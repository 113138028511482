import React from 'react';
import { isEqual, get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { formatDate } from '../../../utils/fudrLocalizationHelper';
import resourcesConfig from '../../../resources';
import { prepareCouponWeekDays } from '../../../selectors';
import { currencies } from '../../../constants/globals';

export const foodItemType = {
  veg: { name: 'VEGETARIAN', code: 'VF' },
  nonVeg: { name: 'NON_VEGETARIAN', code: 'DF' },
  egg: { name: 'EGG', code: '' },
  all: { name: 'ALL', code: 'ALL' },
};

export const foodItemTypeDropdownOptions = [
  { title: foodItemType.all.name, value: foodItemType.all.code },
  { title: foodItemType.veg.name, value: foodItemType.veg.code },
  { title: foodItemType.nonVeg.name, value: foodItemType.nonVeg.code },
];

export const nativeEventType = {
  openScanner: 'open-scanner',
  closeScanner: 'close-scanner',
  enterCode: 'enter-code',
  permissions: 'permissions',
  scannedCode: 'scanned-code',
};

export const extraDiscountCode = 'OUTLETDISCOUNT';

export const tagTypes = {
  pointCredit: 'point_credit',
};

export const validationErrorPriority = {
  dayValidation: 6,
  dateValidation: 4,
  timeValidation: 7,
  redeemValidation: 1,
  expireValidation: 2,
  pauseValidation: 3,
  outletValidation: 5,
  maxAllotmentValidation: 10,
  perGuestMaxValidation: 11,
  pointsValidation: 9,
  rewardValidation: 12,
  amountValidation: 8,
};

export const getHighestPriorityError = (errors) => {
  let highestPriority = Number.MAX_VALUE;
  let highestPriorityError = null;

  // Iterate over each key in the API response
  for (const [key, message] of Object.entries(errors)) {
    // Check if the current error key has a defined priority
    if (validationErrorPriority.hasOwnProperty(key)) {
      const currentPriority = validationErrorPriority[key];

      // Update the highest priority error if the current one has a higher priority
      if (currentPriority < highestPriority) {
        highestPriority = currentPriority;
        highestPriorityError = { key, message };
      }
    }
  }
  return highestPriorityError;
};

export const generateValidationErrorMessage = ({ errorMessageObj, dealCategory, weekdays }) => {
  let errorText = '';
  const preText = isEqual(dealCategory, 'RW') ? (
    <FormattedMessage id="rewardText" defaultMessage={resourcesConfig.rewardText} />
  ) : (
    <FormattedMessage id="offerText" defaultMessage={resourcesConfig.offerText} />
  );
  const errorMessageParams = get(errorMessageObj, 'message.additionalParam');
  switch (get(errorMessageObj, 'key')) {
    case 'dayValidation':
      let applicableWeekdays = get(errorMessageParams, 'applicableWeekdays', []);
      applicableWeekdays = prepareCouponWeekDays(applicableWeekdays, weekdays);
      errorText = (
        <FormattedMessage
          id="dayValidationError"
          defaultMessage={resourcesConfig.dayValidationError}
          values={{ preText, applicableWeekdays }}
        />
      );
      break;
    case 'timeValidation':
      const startTime = get(errorMessageParams, 'startTime', '');
      const endTime = get(errorMessageParams, 'endTime', '');
      errorText = (
        <FormattedMessage
          id="timeValidationError"
          defaultMessage={resourcesConfig.timeValidationError}
          values={{ preText, startTime, endTime }}
        />
      );
      break;
    case 'dateValidation':
      const activationTime = get(errorMessageParams, 'activationTime', '');
      const updatedActivationTime = activationTime && formatDate(activationTime);
      errorText = (
        <FormattedMessage
          id="dateValidationError"
          defaultMessage={resourcesConfig.dateValidationError}
          values={{ preText, activationTime: updatedActivationTime }}
        />
      );
      break;
    case 'amountValidation':
      const minOrderValue = get(errorMessageParams, 'minOrderValue', '');
      errorText = (
        <FormattedMessage
          id="amountValidationError"
          defaultMessage={resourcesConfig.amountValidationError}
          values={{ preText, minOrderValue, currencySymbol: currencies.INR.symbol }}
        />
      );
      break;
    case 'expireValidation':
      const expireDate = get(errorMessageParams, 'expireTime', '');
      const updatedExpireDate = expireDate && formatDate(expireDate);
      errorText = (
        <FormattedMessage
          id={expireDate ? 'expireValidationDateError' : 'expireValidationError'}
          defaultMessage={
            expireDate
              ? resourcesConfig.expireValidationDateError
              : resourcesConfig.expireValidationError
          }
          values={{ preText, expireDate: updatedExpireDate }}
        />
      );
      break;

    default:
      errorText = get(errorMessageParams, 'invalidOfferMsg') ? (
        get(errorMessageParams, 'invalidOfferMsg')
      ) : (
        <FormattedMessage
          id="offerNotApplicableDescription"
          defaultMessage={resourcesConfig.offerNotApplicableDescription}
        />
      );
      break;
  }
  return errorText;
};

export const generateValidationErrorTitle = ({ errorMessageObj, dealCategory }) => {
  let errorText = '';
  const preText = isEqual(dealCategory, 'RW') ? (
    <FormattedMessage id="rewardText" defaultMessage={resourcesConfig.rewardText} />
  ) : (
    <FormattedMessage id="offerText" defaultMessage={resourcesConfig.offerText} />
  );
  switch (get(errorMessageObj, 'key')) {
    case 'amountValidation':
      errorText = (
        <FormattedMessage
          id="amountValidationErrorTitle"
          defaultMessage={resourcesConfig.amountValidationErrorTitle}
        />
      );
      break;
    case 'expireValidation':
      errorText = (
        <FormattedMessage
          id="expireValidationErrorTitle"
          defaultMessage={resourcesConfig.expireValidationErrorTitle}
        />
      );
      break;

    default:
      errorText = (
        <FormattedMessage
          id="offerNotApplicableTitle"
        defaultMessage={resourcesConfig.offerNotApplicableTitle}
          values={{ preText }}
        />
      );
      break;
  }
  return errorText;
};
